<template>
  <div class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
    <main>
      <div class="main_content align-items-center">
        <form method="post" @submit.prevent="addUser" data-vv-scope="addUserForm">
          <div class="row justify-content-between mb_40">
            <div class="col-md-8">
              <div class="heading">
                <h2 v-if="buttonUpdate">Update User</h2>
                <h2 v-else>Add New User</h2>
                <h3>PBE Management Dashboard</h3>
              </div>
            </div>
          </div>

          <div class="card">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">
                    First Name</label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="First Name" name="first_name" v-model="user_data.first_name"
                    v-validate="'required|max:20'" />
                  <small class="error text-danger" v-show="errors.has('addUserForm.first_name')">
                    {{ errors.first("addUserForm.first_name") }}
                  </small>
                </div>
              </div>
              <input type="hidden" v-model="user_data.u_id" />
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">
                    Last Name</label>
                  <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Last Name" name="Last Name" v-model="user_data.last_name"
                    v-validate="'required|max:20'" />
                  <small class="error text-danger" v-show="errors.has('addUserForm.Last Name')">
                    {{ errors.first("addUserForm.Last Name") }}
                  </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">
                    Email</label>
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                    placeholder="Email" name="email" v-model="user_data.email" v-validate="'required|email'" />
                  <small class="error text-danger" v-show="errors.has('addUserForm.email')">
                    {{ errors.first("addUserForm.email") }}
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">
                    Password <svg style="margin-left: 5px;"
                      v-tooltip='"Password must contains with mix of minimum 15 characters and maximum 64 characters in combination of at least one uppercase, lowercase, digits, special character. "'
                      xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                      viewBox="0 0 14 18.346">
                      <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                      <path data-name="Path 9427"
                        d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                        transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                    </svg></label>
                  <input type="password" onpaste="return false;" oncopy="return false;" ondrop="return false;"
                    class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Password"
                    name="Password" v-model="user_data.password"
                    v-validate="($route.params.id) ? { min: 15, max: 64, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ } : { required: true, min: 15, max: 64, regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).*$/ }" />
                  <!-- Password requires 1 of each of the following: uppercase letter, lowercase letter, number, special character. -->
                  <!-- required| -->
                  <small class="error text-danger" v-show="errors.has('addUserForm.Password')">
                    {{ errors.first("addUserForm.Password") }}
                  </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">
                    User Role</label
                  >
                  <v-select
                    :reduce="(userroles) => userroles.id"
                    :options="user_roles"
                    label="display_name"
                    placeholder="User Role"
                    name="User Role"
                    v-model="user_data.role"
                    v-validate="'required'"
                  >
                  </v-select>
                  <small class="error text-danger" v-show="errors.has('addUserForm.User Role')">
                    {{ errors.first("addUserForm.User Role") }}
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">
                    Client <svg style="margin-left: 5px;"
                      v-tooltip='"Need to change region as well after changing the client"'
                      xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                      viewBox="0 0 14 18.346">
                      <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                      <path data-name="Path 9427"
                        d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                        transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                    </svg></label>
                  <div class="check_client">
                    <input type="checkbox" :checked="user_data.admin_sso_status" value="" aria-label="..."
                      class="form-check-input me-2" v-on:click="switchClient($event)" />
                    <span>Disable switch client for the specific user</span>
                    <svg style="margin-left: 5px;" v-tooltip='"Switch to Client reflect under profile drop down menu"'
                      xmlns="http://www.w3.org/2000/svg" data-name="Group 13109" width="16" height="16"
                      viewBox="0 0 14 18.346">
                      <path data-name="Path 9426" d="M0 0h14v14H0z" style="fill: none"></path>
                      <path data-name="Path 9427"
                        d="M9 16a7 7 0 1 1 7-7 7 7 0 0 1-7 7zm0-1.4A5.6 5.6 0 1 0 3.4 9 5.6 5.6 0 0 0 9 14.6zm-.7-9.1h1.4v1.4H8.3zm0 2.8h1.4v4.2H8.3z"
                        transform="translate(-2 -1.654)" style="fill: rgb(109, 109, 109)"></path>
                    </svg>
                  </div>
                  <v-select :reduce="(client_list) => client_list.id" :options="client_list" label="name"
                    placeholder="Client" name="Client" v-model="user_data.client_id" v-validate="'required'"
                    @input="getClientRegions()" />
                  <small class="error text-danger" v-show="errors.has('addUserForm.Client')">
                    {{ errors.first("addUserForm.Client") }}
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">Region</label>
                  <v-select :reduce="(region_list) => region_list.id" :options="region_list" label="name"
                    placeholder="Region" name="Region" v-model="user_data.region" v-validate="'required'" />
                  <small class="error text-danger" v-show="errors.has('addUserForm.Region')">
                    {{ errors.first("addUserForm.Region") }}
                  </small>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="exampleInputEmail1" class="form-label">
                    Temporary Access</label>
                  <div class="input-group bsdatepicker">
                    <!-- <date-picker
                      placeholder="MM DD, YYYY"
                      v-model="user_data.temporary_access"
                      ref="dp2"
                      :config="{ format: 'MM DD, YYYY' }"
                      calendar-class="rounded"
                     :max-date='new Date()'
                    >
                      {{ user_data.temporary_access }}</date-picker
                    > -->
                    <datepicker placeholder="MM DD, YYYY" v-model="user_data.temporary_access" :clear-button="clearButton"
                      class="w-100 newdatepker" ref="dp2" :disabledDates="disabledPastDates" :format="'MMM d, yyyy'">
                    </datepicker>

                    <div class="input-group-prepend dpicker">
                      <button class="btn btn-outline-primary" type="button" @click="showDatePicker">
                        <img src="../../assets/admin/images/calendar.svg" alt="img" />
                      </button>
                    </div>
                  </div>

                  <small class="error text-danger" v-show="errors.has('addUserForm.Temporary Access')">
                    {{ errors.first("addUserForm.Temporary Access") }}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="card mt_24">
            <h5 class="mb_24">Client Data Access</h5>
            <div class="row">
              <div class="col-md-6" v-for="(client_data, indx) in client_list" :key="indx">
                <div class="form_check_box d-flex align-items-center">
                  <input type="checkbox" :value="client_data.id" v-model="user_data.client_ids" aria-label="..."
                    :id="client_data.id" class="form-check-input ms-0" />
                  <label :for="client_data.id" class="">{{
                    client_data.name
                  }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="action_check">
            <div class="row mt_24">
              <div class="row">
                <div class="col-md-6">
                  <div class="form_check_box d-flex align-items-center" v-if="!user_data.id">
                    <input type="checkbox" :checked="user_data.user_notification" value="" aria-label="..."
                      id="flexCheckDefault" class="form-check-input ms-0" v-on:click="sendUserNotification($event)" />
                    <label for="flexCheckDefault" class="">
                      Send User Notification
                    </label>
                  </div>
                  <div class="form_check_box d-flex align-items-center">
                    <input type="checkbox" :checked="user_data.active_status" value="" aria-label="..."
                      id="flexCheckDefault1" class="form-check-input ms-0" v-on:click="accountDeactivated($event)" />
                    <label for="flexCheckDefault1" class=""> Is Active </label>
                  </div>
                  <div class="form_check_box d-flex align-items-center">
                    <input type="checkbox" :checked="user_data.exclude_2fa" value="" aria-label="..."
                      id="flexCheckDefault2" class="form-check-input ms-0" v-on:click="exclude2fa($event)" />
                    <label for="flexCheckDefault2" class=""> Exclude From 2FA</label>
                  </div>
                </div>
              </div>
            </div>

            <div class="action_btn">
              <div class="row mt_24">
                <div class="col">
                  <button v-if="buttonUpdate" type="submit" class="btn btn_primary">
                    Update
                  </button>
                  <button v-else type="submit" class="btn btn_primary">
                    Add
                  </button>
                  <router-link :to="{ name: 'all_user' }">
                    <button href="javascript:void(0);" class="btn btn_outline ml_24">
                      Cancel
                    </button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </main>
    <!-- </div>
        </div>
    </div> -->
  </div>
</template>
<script>
import "vue-select/dist/vue-select.css";
import commonFunction from "../../mixin/commonFunction";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
export default {
  name: "Add_user",
  components: { Datepicker },
  mixins: [commonFunction],
  data() {
    return {
      buttonUpdate: false,
      user_roles: [],
      client_list: [
        // { id: 2, name: "Pure Storage" },
        // { id: 3, name: "Atlassian" },
        // { id: 4, name: "PBE Lab Demo" },
        // { id: 7, name: "PBE Office Demo" },
        // { id: 5, name: "Twitch" },
        // { id: 6, name: "Zendesk" },
      ],
      client_data_access: [
        // { id: 2, name: "Pure Storage" },
        // { id: 3, name: "Atlassian" },
        // { id: 4, name: "PBE Lab Demo" },
        // { id: 7, name: "PBE Office Demo" },
        // { id: 5, name: "Twitch" },
        // { id: 6, name: "Zendesk" },
      ],
      region_list: [],
      /*region_list1: [
        { id: 'EMEA', name: "EMEA" },
        { id: 'APAC', name: "APAC" },
        { id: 'AMER', name: "AMER" },
        { id: 'LATAM', name: "LATAM" },
        { id: 'Other', name: "Other" },
      ],*/
      user_data: {
        id: "",
        u_id: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        role: "",
        client_id: "",
        region: "",
        client_ids: [],
        user_notification: true,
        active_status: false,
        temporary_access: "",
        admin_sso_status: false,
        currentUserId: "",
        exclude_2fa:false,
      },
      disabledPastDates: {
        to: new Date(moment(Date.now()).format("MM/DD/YYYY"))
      },
      clearButton: true,
      oldRegionId: ''
    };
  },
  beforeMount() {
    if (this.$route.params.id != undefined) {
      this.buttonUpdate = true;
      this.getSingleUser(this.$route.params.id);
    }
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.getRoles();
    this.getClients();
  },
  methods: {
    getRoles() {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/roles",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.user_roles = decodedJson.userroles;
        })
        .catch(({ response }) => {
          _this.$toast.error(response.data.error, {
            position: "top-right",
          });
        });
    },
    getClients() {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/active-clients",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          decodedJson.allClients.forEach((element) => {
            this.client_list.push({
              id: element.id,
              name: element.company_name,
            });
          });
        })
        .catch(({ response }) => {
          console.log(response);

        });
    },
    sendUserNotification(event) {
      var _this = this;
      if (event.target.checked) {
        _this.user_data.user_notification = true;
      } else {
        _this.user_data.user_notification = false;
      }
    },
    accountDeactivated(event) {
      var _this = this;
      if (event.target.checked) {
        _this.user_data.active_status = true;
      } else {
        _this.user_data.active_status = false;
      }
    },
    exclude2fa(event) {
      var _this = this;
      if (event.target.checked) {
        _this.user_data.exclude_2fa = true;
      } else {
        _this.user_data.exclude_2fa = false;
      }
    },
    switchClient(event) {
      var _this = this;
      if (event.target.checked) {
        _this.user_data.admin_sso_status = true;
      } else {
        _this.user_data.admin_sso_status = false;
      }
    },
    addUser() {
      //console.log(this.user_data);
      var _this = this;
      if (localStorage.getItem("userToken")) {
        _this.$validator.validateAll("addUserForm").then((isValid) => {
          if (isValid) {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
            if (_this.user_data.u_id) {
              if (_this.user_data.temporary_access == null || _this.user_data.temporary_access == '') {
                _this.user_data.temporary_access = null;
              } else {
                _this.user_data.temporary_access = moment(_this.user_data.temporary_access).format("MM/DD/YYYY");
              }
              let config = {
                method: "put",
                url:
                  process.env.VUE_APP_API_URL +
                  "/api/update-user/" +
                  _this.user_data.u_id,
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },
                data: this.encodeAPIRequestWithImages(_this.user_data),
                //data: _this.user_data,
              };
              _this
                .axios(config)
                .then(({ data }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (data.success) {
                    var decodedJson = _this.decodeAPIResponse(data.data);
                    _this.$toast.success(data.message, {
                      position: "top-right",
                    });
                    _this.buttonUpdate = false;
                    if (decodedJson.user.id == _this.user_data.currentUserId) {
                      window.localStorage.removeItem("companyInformation");
                      window.localStorage.setItem("companyInformation", JSON.stringify(decodedJson.companyData));
                    }
                    _this.$router.push({ name: "all_user" });

                  }
                  else {
                    _this.$toast.error(data.message, {
                      position: "top-right",
                    });
                    _this.$router.push({ name: "all_user" });
                  }
                })
                .catch((err) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (err.response.status == 422) {
                    var err1 = '';
                    for (let x in err.response.data.error) {
                      err1 += err.response.data.error[x];
                      err1 += '<br>';
                    }
                    _this.$toast.error(err1, {
                      position: "top-right",
                    });
                  } else if (err.response.status == 401) {
                    _this.tokenExpired();
                  } else {
                    _this.$toast.error(err.response.data.error, {
                      position: "top-right",
                    });
                  }

                });
            } else {
              let config = {
                method: "post",
                url: process.env.VUE_APP_API_URL + "/api/add-user",
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${window.localStorage.getItem(
                    "userToken"
                  )}`,
                },
                data: this.encodeAPIRequestWithImages(_this.user_data),
              };
              _this.axios(config).then(({ data }) => {
                _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                _this.$toast.success(data.message, {
                  position: "top-right",
                });
                _this.$router.push({ name: "all_user" });
              })
                .catch(({ response }) => {
                  _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
                  if (response.status == 422) {
                    var err1 = '';
                    for (let x in response.data.error) {
                      err1 += response.data.error[x];
                      err1 += '<br>';
                    }
                    _this.$toast.error(err1, {
                      position: "top-right",
                    });
                  } else if (response.status == 401) {
                    _this.tokenExpired();
                  } else {
                    _this.$toast.error(response.data.error, {
                      position: "top-right",
                    });
                  }
                });
            }
          } else {
            console.log(_this.errors);
          }
        });
      } else {
        _this.tokenExpired();
      }
    },
    getSingleUser(id) {
      var _this = this;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/edit-user/" + id,
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.user_data.id = decodedJson.records.id;
            _this.user_data.u_id = decodedJson.records.u_id;
            _this.user_data.first_name = decodedJson.records.first_name;
            _this.user_data.last_name = decodedJson.records.last_name;
            _this.user_data.email = decodedJson.records.email;
            _this.user_data.password = "";
            _this.user_data.role = decodedJson.records.roles[0].id;
            _this.user_data.client_id = decodedJson.records.client_id;
            _this.user_data.region = decodedJson.records.region;
            _this.oldRegionId = decodedJson.records.region;
            _this.user_data.user_notification =
              decodedJson.records.user_notification == 1 ? true : false;
            _this.user_data.currentUserId = decodedJson.records.currentUserId;
            if (decodedJson.records.active_status === 1) {
              _this.user_data.active_status = true;
            }
            if (decodedJson.records.admin_sso_status === 1) {
              _this.user_data.admin_sso_status = true;
            }
            if (decodedJson.records.exclude_2fa === 1) {
              _this.user_data.exclude_2fa = true;
            }
            // _this.user_data.active_status =
            //   decodedJson.records.active_status == 0 ? true : false;
            if (_this.user_data.client_id) {
              _this.getClientRegions();
            }
            if (decodedJson.records.temporary_access != '' && decodedJson.records.temporary_access != null && decodedJson.records.temporary_access != '0000-00-00') {
              _this.user_data.temporary_access = new Date(decodedJson.records.temporary_access);
            }
            _this.user_data.client_ids = JSON.parse(decodedJson.records.client_ids);
          }
          else {
            _this.$toast.error(data.message, {
              position: "top-right",
            });
            _this.$router.push({ name: "all_user" });
          }
        })
        .catch(({ response }) => {
          /*this.$router.push({ name: "all_user" }, () => {
            _this.$toast.error(response.data.error, {
            position: "top-right",
            duration: 5000,
          })
          });*/
          /* _this.$toast.error(response.data.error, {
             position: "top-right",
             duration: 5000,
           });*/
          if (response.status == 404) {
            _this.$router.push({ name: "404" });
          }
          else if (response.status == 403) {
            _this.$router.push({ name: "403" });
          } else {
            _this.$router.push({
              name: "all_user",
              params: { errorMsg: response.data.error }
            });
          }


        });
    },
    showDatePicker() {
      this.$refs.dp2.showCalendar();
    },
    getClientRegions: function () {
      var _this = this;
      if (_this.user_data.client_id != null) {
        _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
        let config = {
          method: "get",
          url:
            process.env.VUE_APP_API_URL +
            "/api/get-client-region/" +
            _this.user_data.client_id,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
          },
          // data: _this.user_data,
        };
        _this
          .axios(config)
          .then(({ data }) => {
            var decodedData = _this.decodeAPIResponse(data.data);
            var chckRegion = true;
            _this.region_list = [];
            // var arrRegions = JSON.parse(data["regions"]);
            var arrRegions = decodedData["regions"];
            for (let x in arrRegions) {
              _this.region_list.push({
                id: arrRegions[x], name: arrRegions[x]
              });
              if (_this.oldRegionId == arrRegions[x]) {
                chckRegion = false;
              }
            }
            if (chckRegion) {
              _this.user_data.region = '';
            }
            else {
              _this.user_data.region = _this.oldRegionId;
            }
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          })
          .catch(({ response }) => {
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          });
      }
    },
  },
};

// import datePicker from "vue-bootstrap-datetimepicker";
// import "bootstrap/dist/css/bootstrap.css";
// // import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
// Vue.use(datePicker);

</script>